import { RecipeCardWithDocument, RecipeCardModel as SharedRecipeCardModel } from "@auto-approve/auto-approve-ui-library";
import axios, { AxiosRequestConfig } from "axios";
import { WORKFLOW_HOST_URL } from "../core/types";
import { RecipeCardModel } from "./RecipeCardServiceTypes";

export const createRecipeCards = async (
  leadOid: string,
  recipeCards: Array<SharedRecipeCardModel>,
  signal: AbortSignal,
  token: string,
) => {
  const url = `${WORKFLOW_HOST_URL}/stipulations/v2/recipe-cards`;
  const body: Array<RecipeCardModel> = recipeCards.map(recipeCard => ({
      leadOid: leadOid,
      ...recipeCard
  }));
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: token
    },
    signal
  };

  return axios
    .post<Array<RecipeCardModel>>(url, body, config)
    .then(response =>{
      if (response) {
        return response.data;
      }
      return Promise.reject("Unable to create document type.");
    });
};

export const deleteRecipeCard = async (
  recipeCardId: number,
  signal: AbortSignal,
  token: string,
  documentId?: string
) => {
  const url = `${WORKFLOW_HOST_URL}/"stipulations/v2/recipe-cards/${recipeCardId}`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: token
    },
    params: {
      documentId
    },
    signal
  };
  return axios
  .delete(url, config)
  .then(response => {
    return response;
  });
};

export const getRecipeCards = (
  leadOid: string,
  lender: string,
  signal: AbortSignal,
  token: string
): Promise<Array<RecipeCardWithDocument>> => {
  const url = `${WORKFLOW_HOST_URL}/v2/recipe-cards`;
    const config: AxiosRequestConfig = {
        headers: {
            Authorization: token
        },
        params: {
          leadOid,
          lender
        },
        signal
    };
  return axios
  .get<Array<RecipeCardWithDocument>>(url, config)
  .then((response) => {
      if (response.data) {
          return response.data;
      }
      return Promise.reject("Unable to retrieve documents and recipe cards.");
  });
};
