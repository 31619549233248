import { Navigate, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./components/Auth/AuthProvider";
import LoginPage from "./components/Login/Login";
import { AuthRequired } from "./components/Auth/AuthRequired";
import { RoleRequired } from "./components/Auth/RoleRequired";
import { TitleSpecialistDashboardProvider } from "./components/Dashboards/context/TitleSpecialistDashboardContext";
import { SharedDashboardProvider } from "./components/Dashboards/context/SharedDashboardContext";
import { EscalationsDashboard } from "./components/Dashboards/EscalationsDashboard/EscalationsDashboard";
import { EscalationsDashboardProvider } from "./components/Dashboards/context/EscalationsDashboardContext";
import { AssociateTitleSpecialistMyQueue } from "./components/Dashboards/AssociateTitleSpecialistMyQueue/AssociateTitleSpecialistMyQueue";
import { AssociateTitleSpecialistMyQueueDashboardProvider } from "./components/Dashboards/context/AssociateTitleSpecialistMyQueueDashboardContext";
import { CreateEstimate } from "./components/CreateEstimate/CreateEstimate";
import { TitleSpecialistInTransitDashboard } from "./components/Dashboards/TitleSpecialistDashboard/TitleSpecialistInTransitDashboard";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { EstimateErrorPage } from "./components/CreateEstimate/EstimateErrorPage";
import { NavBarWithHeader } from "./components/NavBar/NavBar";
import { LandingPage } from "./components/Landing/Landing";
import { TitleOrdersDashboardRouter } from "./components/Dashboards/TitleOrdersDashboard/TitleOrdersDashboardRouter";
import { ThemeProvider } from "@mui/material";
import { Role, theme } from "@auto-approve/auto-approve-ui-library";
import { useFlags } from "launchdarkly-react-client-sdk";
import { DocumentsPage } from "./components/Documents/DocumentsPage";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

export enum ROUTES {
  ROOT = "/",
  MANAGER_DASHBOARD = "/manager-dashboard",
  TITLE_SPECIALIST_DASHBOARD = "/title-specialist-dashboard",
  TITLE_SPECIALIST_IN_TRANSIT_DASHBOARD = "/title-specialist-in-transit",
  ASSOCIATE_TITLE_SPECIALIST_DASHBOARD = "/associate-title-specialist-dashboard",
  ASSOCIATE_TITLE_SPECIALIST_MY_QUEUE = "/associate-title-specialist-my-queue",
  ESCALATIONS = "/escalations",
  CREATE_ESTIMATE = "/create-estimate",
  CREATE_ESTIMATE_ERROR = "/create-estimate-error",
  LANDING = "/landing",
  DOCUMENT_MANAGEMENT = "/document-management",
}
type IAppProps = {
  msalInstance: PublicClientApplication;
};

const queryClient = new QueryClient({ defaultOptions: { queries: { retry: 0, refetchOnWindowFocus: false } } });

export const estimateRoles = [
  Role.LOAN_CONSULTANT,
  Role.MANAGER,
  Role.TITLE_SPECIALIST,
  Role.LOAN_SUPPORT_SPECIALIST,
  Role.LOAN_QUALITY_SPECIALIST,
  Role.SENIOR_LOAN_SUPPORT_SPECIALIST,
  Role.LOAN_PROCESSOR,
  Role.SENIOR_LOAN_PROCESSOR,
];

function App(props: IAppProps) {
  const flags = useFlags();
  return (
    <MsalProvider instance={props.msalInstance}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <NavBarWithHeader />
            <div style={{ paddingTop: "132px", height: "calc(100vh-132px)" }}>
            <Routes>
              <Route path={ROUTES.ROOT} element={<LoginPage />} />
              <Route path={ROUTES.LANDING} element={<LandingPage />} />
              <Route
                path={ROUTES.ASSOCIATE_TITLE_SPECIALIST_DASHBOARD}
                element={
                  <AuthRequired>
                    <RoleRequired roles={[Role.ASSOCIATE_TITLE_SPECIALIST, Role.MANAGER]}>
                      <TitleOrdersDashboardRouter />
                    </RoleRequired>
                  </AuthRequired>
                }
              />
              <Route
                path={ROUTES.ASSOCIATE_TITLE_SPECIALIST_MY_QUEUE}
                element={
                  <AuthRequired>
                    <RoleRequired roles={[Role.ASSOCIATE_TITLE_SPECIALIST]}>
                      <AssociateTitleSpecialistMyQueueDashboardProvider>
                        <SharedDashboardProvider>
                          <AssociateTitleSpecialistMyQueue />
                        </SharedDashboardProvider>
                      </AssociateTitleSpecialistMyQueueDashboardProvider>
                    </RoleRequired>
                  </AuthRequired>
                }
              />
              <Route
                path={ROUTES.TITLE_SPECIALIST_DASHBOARD}
                element={
                  <AuthRequired>
                    <RoleRequired roles={[Role.TITLE_SPECIALIST, Role.MANAGER]}>
                      <TitleOrdersDashboardRouter />
                    </RoleRequired>
                  </AuthRequired>
                }
              />
              <Route
                path={ROUTES.MANAGER_DASHBOARD}
                element={
                  <AuthRequired>
                    <RoleRequired roles={[Role.TITLE_SPECIALIST, Role.MANAGER]}>
                      <TitleOrdersDashboardRouter />
                    </RoleRequired>
                  </AuthRequired>
                }
              />
              <Route
                path={ROUTES.TITLE_SPECIALIST_IN_TRANSIT_DASHBOARD}
                element={
                  <AuthRequired>
                    <RoleRequired roles={[Role.TITLE_SPECIALIST]}>
                      <TitleSpecialistDashboardProvider>
                        <SharedDashboardProvider>
                          <TitleSpecialistInTransitDashboard />
                        </SharedDashboardProvider>
                      </TitleSpecialistDashboardProvider>
                    </RoleRequired>
                  </AuthRequired>
                }
                />
                {flags.titlesEscalationsFeature &&
                  <Route
                    path={ROUTES.ESCALATIONS}
                    element={
                      <AuthRequired>
                        <RoleRequired roles={[Role.MANAGER]}>
                          <EscalationsDashboardProvider>
                            <SharedDashboardProvider>
                              <EscalationsDashboard />
                            </SharedDashboardProvider>
                          </EscalationsDashboardProvider>
                        </RoleRequired>
                      </AuthRequired>
                    }
                  />}
                <Route
                  path={ROUTES.CREATE_ESTIMATE}
                  element={
                    <AuthRequired>
                      <RoleRequired roles={estimateRoles}>
                        <CreateEstimate />
                      </RoleRequired>
                    </AuthRequired>
                  }
                />
                <Route
                  path={ROUTES.CREATE_ESTIMATE_ERROR}
                  element={
                    <AuthRequired>
                      <RoleRequired roles={estimateRoles}>
                        <EstimateErrorPage />
                      </RoleRequired>
                    </AuthRequired>
                  }
                />
                <Route
                  path={ROUTES.DOCUMENT_MANAGEMENT}
                  element={
                    <AuthRequired>
                      <RoleRequired roles={Object.values(Role)}>
                        <DocumentsPage /> 
                      </RoleRequired>
                    </AuthRequired>
                  }
                />
                {/* Invalid Route: should be last */}
                <Route path="*" element={<Navigate to="/" replace={true} />} />
              </Routes>
            </div>
          </ThemeProvider>
        </AuthProvider>
      </QueryClientProvider>
    </MsalProvider>
  );
}

export default App;
